import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

// Import thirdweb provider and Rinkeby ChainId
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { ChainId } from "@thirdweb-dev/sdk";

// This is the chainId your dApp will work on.
const activeChainId = ChainId.Goerli;

const container = document.getElementById("root");
const root = createRoot(container);

// Wrap your app with the thirdweb provider
root.render(
  <React.StrictMode>
    <ThirdwebProvider desiredChainId={activeChainId}>
      <App />
    </ThirdwebProvider>
  </React.StrictMode>
);
