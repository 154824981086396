import {
  useAddress,
  useNetwork,
  ConnectWallet,
  Web3Button,
  useContract,
  useNFTBalance,
} from "@thirdweb-dev/react";
import { ChainId } from "@thirdweb-dev/sdk";

import { useState, useEffect, useMemo } from "react";
import { AddressZero } from "@ethersproject/constants";

const App = () => {
  const address = useAddress();
  const network = useNetwork();

  console.log("address", address);

  const editionDropAddress = "0xaF3d4710d560b52eeC2C2B322E0d346338CaDa29";
  const tokenAddress = "0x73b503a5A7FF1046046f5b271bb5550B3070d589";
  const voteAddress = "0x5F0C8dA9a4bB962E181C2972BdFd60ce91231112";

  const { contract: editionDrop } = useContract(
    editionDropAddress,
    "edition-drop"
  );

  const { contract: token } = useContract(tokenAddress, "token");
  const { contract: vote } = useContract(voteAddress, "vote");
  const { data: nftBalance } = useNFTBalance(editionDrop, address, "0");

  const hasClaimedNFT = useMemo(() => {
    return nftBalance && nftBalance.gt(0);
  }, [nftBalance]);

  const [memberTokenAmounts, setMemeberTokenAmounts] = useState([]);

  const [memberAddresses, setMemberAddresses] = useState([]);

  const shortenAddress = (str) => {
    return str.substring(0, 6) + "..." + str.substring(str.length - 4);
  };

  const [proposals, setProposals] = useState([]);
  const [isVoting, setIsVoting] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);

  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }
    const getAllProposals = async () => {
      try {
        const proposals = await vote.getAll();
        setProposals(proposals);
        console.log("got all proposals");
      } catch (error) {
        console.error("Getting proposals failed", error);
      }
    };
    getAllProposals();
  }, [hasClaimedNFT, vote]);

  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }

    if (!proposals.length) {
      return;
    }

    const checkIfUserHasVoted = async () => {
      try {
        const hasVoted = await vote.hasVoted(proposals[0].proposalId, address);
        setHasVoted(hasVoted);
        if (hasVoted) {
          console.log("user has voted");
        } else {
          console.log("user has not voted");
        }
      } catch (error) {
        console.error("failed getting votes", error);
      }
    };
    checkIfUserHasVoted();
  }, [hasClaimedNFT, proposals, address, vote]);

  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }
    const getAllAddresses = async () => {
      try {
        const memberAddresses =
          await editionDrop?.history.getAllClaimerAddresses(0);
        setMemberAddresses(memberAddresses);
        console.log("member addresses", memberAddresses);
      } catch (error) {
        console.error("getting addresses failed:", error);
      }
    };
    getAllAddresses();
  }, [hasClaimedNFT, editionDrop?.history]);

  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }

    const getAllBalances = async () => {
      try {
        const amounts = await token?.history.getAllHolderBalances();
        setMemeberTokenAmounts(amounts);
        console.log("Amounts:", amounts);
      } catch (error) {
        console.log("failed getting balaces", error);
      }
    };
    getAllBalances();
  }, [hasClaimedNFT, token?.history]);

  const memberList = useMemo(() => {
    return memberAddresses.map((address) => {
      const member = memberTokenAmounts?.find(
        ({ holder }) => holder === address
      );
      return {
        address,
        tokenAmount: member?.balance.displayValue || 0,
      };
    });
  }, [memberAddresses, memberTokenAmounts]);

  if (address && network?.[0].data.chain.id !== ChainId.Goerli) {
    return (
      <div className='unsupported-network'>
        <h2>Please connect to Goerli</h2>
        <p>
          This dapp only works on the Goerli network, please switch networks in
          your connected wallet.
        </p>
      </div>
    );
  }

  if (!address) {
    return (
      <div className='landing'>
        <h1>Welcome to WineDAO</h1>
        <div className='btn-hero'>
          <ConnectWallet />
        </div>
      </div>
    );
  }

  if (hasClaimedNFT) {
    return (
      <div className='member-page'>
        <h2>WineDAO member page</h2>
        <p>Cheers, Cin Cin, Skål</p>
        <div>
          <div>
            <h2>Member list</h2>
            <table className='card'>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Token Amount</th>
                </tr>
              </thead>
              <tbody>
                {memberList.map((member) => {
                  return (
                    <tr key={member.address}>
                      <td>{shortenAddress(member.address)}</td>
                      <td>{member.tokenAmount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div>
            <h2>Active Proposals</h2>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                e.stopPropagation();

                setIsVoting(true);

                const votes = proposals.map((proposal) => {
                  const voteResult = {
                    proposalId: proposal.proposalId,
                    vote: 2,
                  };
                  proposal.votes.forEach((vote) => {
                    const elem = document.getElementById(
                      proposal.proposalId + "-" + vote.type
                    );

                    if (elem.checked) {
                      voteResult.vote = vote.type;
                      return;
                    }
                  });
                  return voteResult;
                });

                try {
                  // delegate tokens
                  const delegation = await token.getDelegationOf(address);
                  if (delegation === AddressZero) {
                    await token.delegateTo(address);
                  }

                  // vote
                  try {
                    await Promise.all(
                      votes.map(async ({ proposalId, vote: _vote }) => {
                        const proposal = await vote.get(proposalId);
                        if (proposal.state === 1) {
                          return vote.vote(proposalId, _vote);
                        }
                        return;
                      })
                    );
                    try {
                      await Promise.all(
                        votes.map(async ({ proposalId }) => {
                          const proposal = await vote.get(proposalId);
                          if (proposal.state === 4) {
                            return vote.execute(proposalId);
                          }
                        })
                      );

                      setHasVoted(true);
                      console.log("Successfuly voted");
                    } catch (error) {
                      console.error("failed to execute vote", error);
                    }
                  } catch (error) {
                    console.error("Voting failed", error);
                  }
                } catch (error) {
                  console.error("Failed to delegate tokens ", error);
                } finally {
                  setIsVoting(false);
                }
              }}
            >
              {proposals.map((proposal) => (
                <div key={proposal.proposalId} className='card'>
                  <h5>{proposal.description}</h5>
                  <div>
                    {proposal.votes.map(({ type, label }) => (
                      <div key={type}>
                        <input
                          type='radio'
                          id={proposal.proposalId + "-" + type}
                          name={proposal.proposalId}
                          value={type}
                          defaultChecked={type === 2}
                        />
                        <label htmlFor={proposal.proposalId + "-" + type}>
                          {label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <button disabled={isVoting || hasVoted} type='submit'>
                {isVoting
                  ? "Voting..."
                  : hasVoted
                  ? "You Already Voted"
                  : "Submit votes"}
              </button>
              {!hasVoted && (
                <small>
                  This will trigger multiple transactions that you will have to
                  sign.
                </small>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='mint-nft'>
      <h1>Mint your free WineDAO Membership NFT</h1>
      <div className='btn-hero'>
        <Web3Button
          contractAddress={editionDropAddress}
          action={(contract) => {
            contract.erc1155.claim(0, 1);
          }}
          onSuccess={() => {
            console.log(
              `Successfully Minted! Check it out on OpenSea: https://testnets.opensea.io/assets/${editionDrop.getAddress()}/0`
            );
          }}
          onError={(error) => {
            console.error("failed, error");
          }}
        >
          Mint your NFT(free)
        </Web3Button>
      </div>
    </div>
  );
};

export default App;
